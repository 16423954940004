import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";

import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { t } from "i18next";

const GridItem = ({ icon, title, description, link, progress, isConsultant}) => {
    const navigate = useNavigate();

    return (
            <Grid item xs={12} sm={6} md={4}>
                <Paper
                    sx={{
                        px: 4,
                        py: 3,
                        borderRadius: 8,
                        boxShadow: '0px 4px 20px rgba(1, 79, 134, 0.07)'

                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: { xs: 60 },
                        }}
                        alt="business idea icon"
                        src={icon}
                    />
                    <Box
                        sx={{
                            height: 140,
                        }}
                    >
                        <Typography variant="businessIdeasTitle" component="h2" sx={{ flexGrow: 1, mt: 1 }}>
                            {title}
                        </Typography>
                        <Typography variant='businessIdeasDescription' component="p" sx={{ mt: 1 }}>
                            {description}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '100%', my: 2 }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{
                                bgcolor: '#FFD100',
                                color: '#000',
                                borderRadius: 10,
                                my: 1,
                                mr: 0.5,
                                px: 3,
                                textTransform: 'none',
                                width: '100%',
                                //hover
                                '&:hover': {
                                    bgcolor: '#FFD100',
                                },
                            }}
                            onClick={() => navigate(link)}>
                            {isConsultant ? t('button.view') : t('button.make_changes')}
                            
                        </Button>
                    </Box>
                </Paper>
            </Grid>
    );
}

export default GridItem;