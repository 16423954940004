import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import usersReducer from '../features/users'
import utilsReducer from '../features/utils'
import businesIdeasReducer from '../features/business_ideas'
import questionsReducer from '../features/questions'
import openAnswerReducer from '../features/openAnswer'
import notesReducer from '../features/notes'

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  users: usersReducer,
  utils: utilsReducer,
  business_ideas: businesIdeasReducer,
  questions: questionsReducer,
  openAnswer: openAnswerReducer,
  notes: notesReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'users/logout') {
    state.users = undefined
    state.business_ideas = undefined
  }
  return reducers(state, action)
}


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };



