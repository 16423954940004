import * as React from 'react';

import Grid from '@mui/material/Grid'; // Grid version 1
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// make home page
const TitleBar = ({ title, description }) => {
    return (
        
        <Grid item xs={12} sx={{ background: '#014F8610', py: 6}}>
            <Container maxWidth="lg"  sx={{  display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography component="h1" variant='titleBarTitle'>
                        {title}
                    </Typography>
                    {description ? 
                    <Typography component="p" variant='titleBarDescription' sx={{ mt: 2 }}>
                        {description}
                    </Typography> : null }
            </Container>
        </Grid>
    );
}

export default TitleBar;