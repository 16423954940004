import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../app/api'

const initialState = {
    countries: null,
    companySectors: null,
    status: 'idle',
    error: null
}

export const getCountries = createAsyncThunk(
    "utils/getCountries",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('utils/countries')
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getCompanySectors = createAsyncThunk(
    "utils/getCompanySectors",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('utils/companySectors')
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    extraReducers: {
        [getCountries.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getCountries.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.countries = action.payload
        },
        [getCountries.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [getCompanySectors.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getCompanySectors.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.companySectors = action.payload
        },
        [getCompanySectors.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        }
    }
})

export default utilsSlice.reducer
