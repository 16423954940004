// mui footer with copiright

import * as React from 'react';
import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import logo from '../res/images/logos-gb.png';
import logo_econevrokop from '../res/images/logo-econevrokop.png';

import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <Grid>
        <Grid container sx={{marginTop: 'auto'}} component='footer'>
            <Grid item xs={12} sx={{ background: '#a3abd7', py: 6, color: '#fff'}}>
                <Container maxWidth="lg"  sx={{  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                    <Grid container spacing={2} sx={{alignItems: 'center'}}>
                        <Grid item xs={12} sm={6} md={8}>
                            <Typography component="h2" variant='h6'>
                                {t('footer.title')}
                            </Typography>
                            <Typography component="p" variant='body2'>
                                {t('footer.description')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center'}}>
                            <div>
                            <a href="https://ec.europa.eu/regional_policy/home_en" target='_blank'>
                                <Box
                                    component="img"
                                    sx={{
                                        width: {
                                            xs: 250,
                                            sm: 250,
                                            md: 300,
                                            lg: 350,
                                        },
                                    }}
                                    alt="logo"
                                    src={logo}
                                />
                            </a>
                            </div>
                            <div>
                            <a href="http://econevrokop.eu/" target='_blank'>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 100 },
                                        mt: 4
                                    }}
                                    alt="logo_econevrokop"
                                    src={logo_econevrokop}
                                />
                            </a>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
        <Grid container sx={{marginTop: 'auto'}} component='footer'>
            <Grid item xs={12} sx={{ background: '#014F86', py: 2}}>
                <Container maxWidth="lg"  sx={{  display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography component="p" variant='copiright'>
                        {t('footer.copyright')}
                    </Typography>
                </Container>
            </Grid>
        </Grid>
        </Grid>
    );
}

export default Footer;