import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import landingheroimage from '../res/images/landing-hero-image.png'

import find_partner from '../res/images/find_partner.png'
import step1 from '../res/images/step1.png'
import step2 from '../res/images/step2.png'
import step3 from '../res/images/step3.png'
import step4 from '../res/images/step4.png'
import landingimage1 from '../res/images/landing-section-1.png'

import { useTranslation } from 'react-i18next';

const HeroImage = styled('div')({
    backgroundImage: `url(${landingheroimage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
});


// make home page
const HomePage = () => {
    const { t } = useTranslation();

    return (
        <Grid container >
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <HeroImage sx={{ py: 20, backgroundImage: landingheroimage }}>
                        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography component="h1" variant='heroHeading1'>
                                {t('homePage.heroHeading1')}
                            </Typography>
                            <Typography component="p" variant='heroDescription' sx={{ mt: 2 }}>
                                {t('homePage.heroDescription')}
                            </Typography>
                            <Box maxWidth='300px' sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Button variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, my: 2 }}
                                    component={Link} to="/login"
                                >
                                    {t('button.start')}
                                </Button>
                            </Box>
                        </Container>
                    </HeroImage>
                </Grid>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Grid container spacing={5} sx={{ my: 3 }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', px: 2 }}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={find_partner}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="span" variant='smallDescription'>{t('homePage.advantages1')}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', px: 2 }}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={find_partner}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="span" variant='smallDescription'>{t('homePage.advantages2')}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', px: 2 }}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={find_partner}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="span" variant='smallDescription'>{t('homePage.advantages3')}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', px: 2 }}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={find_partner}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="span" variant='smallDescription'>{t('homePage.advantages4')}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', px: 2 }}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={find_partner}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="span" variant='smallDescription'>
                                        {t('homePage.advantages5')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Grid container xs={12} sx={{ background: '#014F8610', py: 6 }} id="about">
                    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Grid container spacing={5} sx={{ my: 3, alignItems: 'center' }}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography component="span" variant='beforeSectionHeading' sx={{ mb: 1 }}>
                                    {t('homePage.about')}
                                </Typography>
                                <Typography component="h2" variant='sectionHeading' sx={{ mb: 1 }}>
                                    {t('homePage.aboutHeading')}
                                </Typography>
                                <Typography component="p" variant='p' sx={{ mt: 2 }}>
                                    {t('homePage.aboutText')}
                                </Typography>
                                <Typography component="p" variant='p' sx={{ mt: 2 }}>
                                    {t('homePage.aboutText2')}
                                </Typography>
                                <Typography component="p" variant='p' sx={{ mt: 2 }}>
                                    {t('homePage.aboutText3')}
                                </Typography>
                                <Typography component="p" variant='p' sx={{ mt: 2 }}>
                                    {t('homePage.aboutText4')}
                                </Typography>
                                <Button variant="contained" sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, my: 2 }}>
                                    {t('button.start')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center'}}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: '90%' },
                                        height: { xs: 300 },
                                        objectFit: "cover",
                                        // ml: 4
                                    }}
                                    alt="business idea icon"
                                    src={landingimage1}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12} sx={{ py: 4 }} id="how">
                    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Grid container spacing={5} sx={{ my: 3, textAlign: 'center' }}>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <Typography component="span" variant='beforeSectionHeading' sx={{ mb: 1 }}>
                                    {t('homePage.principles')}
                                </Typography>
                                <Typography component="h2" variant='sectionHeading' sx={{ mb: 1 }}>
                                    {t('homePage.how')}
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={6} lg={3}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={step1}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="h3" variant='smallHeading' sx={{ mb: 1 }}>{t('homePage.step1')}</Typography>
                                    <Typography component="h4" variant='smallHeading2' sx={{ mb: 1 }}>{t('homePage.step1Text')}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={6} lg={3}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={step2}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="h3" variant='smallHeading' sx={{ mb: 1 }}> {t('homePage.step2')} </Typography>
                                    <Typography component="h4" variant='smallHeading2' sx={{ mb: 1 }}> {t('homePage.step2Text')} </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={6} lg={3}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={step3}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="h3" variant='smallHeading' sx={{ mb: 1 }}> {t('homePage.step3')} </Typography>
                                    <Typography component="h4" variant='smallHeading2' sx={{ mb: 1 }}> {t('homePage.step3Text')} </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={6} lg={3}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: 60 },
                                        height: { xs: 60 }
                                    }}
                                    alt="business idea icon"
                                    src={step4}
                                />
                                <Box
                                    sx={{ ml: 2 }}
                                >
                                    <Typography component="h3" variant='smallHeading' sx={{ mb: 1 }}> {t('homePage.step4')} </Typography>
                                    <Typography component="h4" variant='smallHeading2' sx={{ mb: 1 }}> {t('homePage.step4Text')} </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default HomePage;