import jwtDecode from 'jwt-decode';

const checkAuth = async () => {
    // Retrieve the JWT from local storage or secure storage
    const jwt = localStorage.getItem('token');
    if (!jwt) {
      // If no JWT is present, return false
      return false;
    }
  
    try {
      // Decode the JWT using a library such as jwt-decode
      const decoded = jwtDecode(jwt);
    
      // Check if the token has expired
      if (decoded.exp < Date.now() / 1000) {
        console.log('token expired');
        // If the token has expired, return false
        return false;
      }
  
      // If the token is valid, return true
      return true;
    } catch (err) {
      // If there is an error decoding the token, return false
      return false;
    }
  };

  export default checkAuth