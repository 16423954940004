import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../app/api'

const initialState = {
    answer: null,
    status: 'idle',
    error: null
}

export const getAnswer = createAsyncThunk(
    "openanswers/getOpenanswers",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await api.get('openanswers/' + arg.business_idea_id + '/' + arg.type);
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createAnswer = createAsyncThunk(
    "openanswers/createOpenanswers",
    async ({
        answer, business_idea_id, type
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('openanswers', {
                answer: answer,
                type: type,
                business_idea_id: business_idea_id,
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAnswer = createAsyncThunk(
    "openanswers/updateOpenanswers",
    async ({
        answer, id
    }, { rejectWithValue }) => {
        try {
            const response = await api.put('openanswers/' + id, {
                answer: answer,
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const openanswersSlice = createSlice({
    name: 'openanswers',
    initialState,
    extraReducers: {
        [getAnswer.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getAnswer.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.answer = action.payload
            state.error = null
        },
        [getAnswer.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [createAnswer.pending]: (state, action) => {
            state.status = 'loading'
        },
        [createAnswer.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [createAnswer.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [updateAnswer.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updateAnswer.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [updateAnswer.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        }
    }
});

export default openanswersSlice.reducer;