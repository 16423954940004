import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';

import TitleBar from '../../components/TitleBar';
import GridItem from '../../components/GridItem';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography"

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


import business_idea_icon from '../../res/images/business_idea_icon.png';
import key_partners from '../../res/images/key_partners.png';
import key_activities from '../../res/images/key_activities.png';
import key_resources from '../../res/images/key_resources.png';
import value_propositions from '../../res/images/value_proposition.png';
import customer_relationships from '../../res/images/customer_relationships.png';
import channels from '../../res/images/channels.png';
import customer_segments from '../../res/images/customer_segments.png';
import cost_structure from '../../res/images/cost_structure.png';
import revenue_streams from '../../res/images/revenue_streams.png';

import { saveAs } from 'file-saver';

import { getBusinessIdea, getBusinessIdeaProgress, getBusinessDocument, generateImage, getBusinessDocumentConsultant } from '../../features/business_ideas'
import { useTranslation } from 'react-i18next';

const BusinessIdeaDashboard = ({ isConsultant }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const [project_name, setProjectName] = useState('');
    const [short_description, setShortDescription] = useState('');
    const [progress, setProgress] = useState([]);
    const [fullProgress, setFullProgress] = useState(0);

    const [error, setError] = useState('')

    useEffect(() => {
        dispatch(getBusinessIdea(id)).then((response) => {
            if (response.payload.message === 'Business idea not found') {
                navigate('/dashboard', { state: { message: t('editBusiness.not_found') } });
            } else {
                setProjectName(response.payload.project_name);
                setShortDescription(response.payload.short_description);
            }
        });

        dispatch(getBusinessIdeaProgress({
            id: id,
            isConsultant: isConsultant
        })).then((response) => {
            setProgress(response.payload);
            let total = 0;
            response.payload.forEach((item) => {
                total += parseInt(item.progress);
            })
            setFullProgress(total / response.payload.length);
        });


    }, [])

    const generateDocument = () => {
        dispatch(getBusinessDocument(id)).then((res) => {
            if (res.payload.url) {
                fetch(res.payload.url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'business_idea.docx'; // Set desired filename here
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        setError('Failed to save the image');
                        console.error(error);
                    });
            } else {
                setError('Something went wrong, please try again')
            }
        })
        dispatch(generateImage(id)).then((res) => {
            if (res.payload.url) {
                fetch(res.payload.url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'business_idea.png'; // Set desired filename here
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        setError('Failed to save the image');
                        console.error(error);
                    });
            } else {
                setError('Something went wrong, please try again')
            }
        })
    }

    const generateDocumentConsultant = () => {
        dispatch(getBusinessDocumentConsultant({
            id: id,
            areaOfConsultant: areaOfConsultant,
            datesOfConsultant: datesOfConsultant,
            totalHoursOfConsultant: totalHoursOfConsultant
        })).then((res) => {
            handleClose()
            if (res.payload.url) {
                fetch(res.payload.url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'report.docx'; // Set desired filename here
                        a.click();
                        window.URL.revokeObjectURL(url);
                        // go to dashboard after download
                        navigate('/consultant');
                    })
                    .catch((error) => {
                        setError('Failed to save the image');
                        console.error(error);
                    });
            } else {
                setError('Something went wrong, please try again')
            }
        })
    }

    const [areaOfConsultant, setAreaOfConsultant] = useState([])
    const [datesOfConsultant, setDatesOfConsultant] = useState([])
    const [totalHoursOfConsultant, setTotalHoursOfConsultant] = useState(0)

    //open modal for consultant report when click on consultant report button

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };


    return (
        <Grid container >
            <TitleBar title={project_name} description={short_description.substring(0, 50)} />
            <Grid item xs={12}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Grid container spacing={5} sx={{ my: 3 }}>
                        <GridItem
                            title={t('business.key_partner_title')}
                            description={t('business.key_partner')}
                            icon={key_partners}
                            link={`/business_ideas/${id}/key_partners`}
                            progress={Number(progress.find(item => item.type === 'key_partners')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.key_activities_title')}
                            description={t('business.key_activities')}
                            icon={key_activities}
                            link={`/business_ideas/${id}/key_activities`}
                            progress={Number(progress.find(item => item.type === 'key_activities')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.key_resources_title')}
                            description={t('business.key_resources')}
                            icon={key_resources}
                            link={`/business_ideas/${id}/key_resources`}
                            progress={Number(progress.find(item => item.type === 'key_resources')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.value_proposition_title')}
                            description={t('business.value_proposition')}
                            icon={value_propositions}
                            link={`/business_ideas/${id}/value_propositions`}
                            progress={Number(progress.find(item => item.type === 'value_propositions')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.customer_relationships_title')}
                            description={t('business.customer_relationships')}
                            icon={customer_relationships}
                            link={`/business_ideas/${id}/customer_relationships`}
                            progress={Number(progress.find(item => item.type === 'customer_relationships')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.channels_title')}
                            description={t('business.channels')}
                            icon={channels}
                            link={`/business_ideas/${id}/channels`}
                            progress={Number(progress.find(item => item.type === 'channels')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.customer_segments_title')}
                            description={t('business.customer_segments')}
                            icon={customer_segments}
                            link={`/business_ideas/${id}/customer_segments`}
                            progress={Number(progress.find(item => item.type === 'customer_segments')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.cost_structure_title')}
                            description={t('business.cost_structure')}
                            icon={cost_structure}
                            link={`/business_ideas/${id}/cost_structure`}
                            progress={Number(progress.find(item => item.type === 'cost_structure')?.progress)}
                            isConsultant={isConsultant}
                        />
                        <GridItem
                            title={t('business.revenue_streams_title')}
                            description={t('business.revenue_streams')}
                            icon={revenue_streams}
                            link={`/business_ideas/${id}/revenue_streams`}
                            progress={Number(progress.find(item => item.type === 'revenue_streams')?.progress)}
                            isConsultant={isConsultant}
                        />
                    </Grid>
                    {!isConsultant ?
                        <Grid container sx={{ my: 3, mb: 6 }}>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                {error ?
                                    <Typography variant="body2" color="error" sx={{ mt: 1, mb: 1 }}>
                                        {error}
                                    </Typography>
                                    : null}
                                {fullProgress === 100 ?
                                    <Button
                                        sx={{
                                            bgcolor: '#014F86',
                                            color: '#fff',
                                            borderRadius: 10,
                                            px: 3,
                                            textTransform: 'none',
                                            //hover
                                            '&:hover': {
                                                bgcolor: '#014F86',
                                            },
                                            //active
                                            '&:active': {
                                                bgcolor: '#014F86',
                                            },
                                            //focus
                                            '&:focus': {
                                                bgcolor: '#014F86',
                                            }
                                        }}
                                        onClick={() => generateDocument()}
                                    >
                                        {t('button.finish')}
                                    </Button> :
                                    <Button
                                        sx={{
                                            bgcolor: '#014F86',
                                            color: '#fff',
                                            borderRadius: 10,
                                            px: 3,
                                            textTransform: 'none',
                                            //hover
                                            '&:hover': {
                                                bgcolor: '#014F86',
                                            },
                                            //active
                                            '&:active': {
                                                bgcolor: '#014F86',
                                            },
                                            //focus
                                            '&:focus': {
                                                bgcolor: '#014F86',
                                            }
                                        }}
                                        onClick={() => setError('Please complete all the sections')}
                                    >
                                        {t('button.finish')}
                                    </Button>}

                            </Grid>
                        </Grid> :
                        <Grid container sx={{ my: 3, mb: 6 }}>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                {error ?
                                    <Typography variant="body2" color="error" sx={{ mt: 1, mb: 1 }}>
                                        {error}
                                    </Typography>
                                    : null}
                                {fullProgress === 100 ?
                                    <Button
                                        sx={{
                                            bgcolor: '#014F86',
                                            color: '#fff',
                                            borderRadius: 10,
                                            px: 3,
                                            textTransform: 'none',
                                            //hover
                                            '&:hover': {
                                                bgcolor: '#014F86',
                                            },
                                            //active
                                            '&:active': {
                                                bgcolor: '#014F86',
                                            },
                                            //focus
                                            '&:focus': {
                                                bgcolor: '#014F86',
                                            }
                                        }}
                                        // onClick={() => generateDocumentConsultant()}
                                        onClick={handleOpen}
                                    >
                                        {t('button.finishConsultant')}
                                    </Button> :
                                    <Button
                                        sx={{
                                            bgcolor: '#014F86',
                                            color: '#fff',
                                            borderRadius: 10,
                                            px: 3,
                                            textTransform: 'none',
                                            //hover
                                            '&:hover': {
                                                bgcolor: '#014F86',
                                            },
                                            //active
                                            '&:active': {
                                                bgcolor: '#014F86',
                                            },
                                            //focus
                                            '&:focus': {
                                                bgcolor: '#014F86',
                                            }
                                        }}
                                        onClick={() => setError('Please complete all the sections')}
                                    >
                                        {t('button.finishConsultant')}
                                    </Button>}
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            {t('button.finishConsultantHeading')}
                                        </Typography>
                                        <TextField
                                            label={t('consultant.areaOf')}
                                            variant="outlined"
                                            value={areaOfConsultant}
                                            onChange={(e) => setAreaOfConsultant(e.target.value)}
                                            sx={{ mt: 2, backgroundColor: '#fff', width: '100%' }}
                                        />
                                        <TextField
                                            label={t('consultant.datesOfConsultant')}
                                            placeholder='DD/MM/YYYY - DD/MM/YYYY'
                                            variant="outlined"
                                            value={datesOfConsultant}
                                            onChange={(e) => setDatesOfConsultant(e.target.value)}
                                            sx={{ mt: 2, backgroundColor: '#fff', width: '100%' }}
                                        />
                                        <TextField
                                            label={t('consultant.totalHoursOfConsultant')}
                                            variant="outlined"
                                            value={totalHoursOfConsultant}
                                            onChange={(e) => setTotalHoursOfConsultant(e.target.value)}
                                            sx={{ mt: 2, backgroundColor: '#fff', width: '100%' }}
                                            type="number"
                                            inputProps={{ min: 0 }}
                                        />

                                        <Button
                                            sx={{
                                                bgcolor: '#014F86',
                                                color: '#fff',
                                                borderRadius: 10,
                                                px: 3,
                                                mt: 2,
                                                textTransform: 'none',
                                                //hover
                                                '&:hover': {
                                                    bgcolor: '#014F86',
                                                },
                                                //active
                                                '&:active': {
                                                    bgcolor: '#014F86',
                                                },
                                                //focus
                                                '&:focus': {
                                                    bgcolor: '#014F86',
                                                }
                                            }}
                                            onClick={() => generateDocumentConsultant()}
                                        >
                                            {t('button.finishConsultant')}
                                        </Button>

                                    </Box>
                                </Modal>
                            </Grid>
                        </Grid>
                    }
                </Container>
            </Grid>
        </Grid>
    );
}

export default BusinessIdeaDashboard;