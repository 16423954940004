import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Button,
} from '@mui/material';

import TitleBar from '../../components/TitleBar';

import { getBusinessIdeasConsultant } from '../../features/business_ideas';
import { useTranslation } from 'react-i18next';

const DashboardConsultant = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getBusinessIdeasConsultant()).then((res) => {
            setData(res.payload)
        })
    }, [])

    const business_ideas = useSelector((state) => state.business_ideas.business_ideas)

    const [searchTerm, setSearchTerm] = useState('');

    // Filter the data based on the search term
    const filteredData = data.filter((item) =>
        item.User.UserInfo.company.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Handle search term change
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Grid container >
            <TitleBar title={t('consultant.all_buisiness_plans')} />

            <Grid item xs={12}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 4 }}>
                    <TextField
                        label={t('consultant.search')}
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        sx={{mb: 2}}
                    />
                    <TableContainer>
                        <Table sx={{ border: '1px solid #014F86' }}>
                            <TableHead sx={{ border: '1px solid #014F86' }}>
                                <TableRow sx={{ border: '1px solid #014F86', background: '#014F8605' }}>
                                    <TableCell sx={{ border: '1px solid #014F86', fontWeight: 600, color: '#014F86'}}>Company Name</TableCell>
                                    <TableCell sx={{ border: '1px solid #014F86', fontWeight: 600, color: '#014F86'}}>Business Plan Name</TableCell>
                                    <TableCell sx={{ border: '1px solid #014F86', fontWeight: 600, color: '#014F86'}}>Industry</TableCell>
                                    <TableCell sx={{ border: '1px solid #014F86', fontWeight: 600, color: '#014F86'}}>Opperational Address</TableCell>
                                    <TableCell sx={{ border: '1px solid #014F86', fontWeight: 600, color: '#014F86'}}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{borderTop: '1px solid #014F8650',}}>
                                {filteredData.map((item) => (
                                    <TableRow key={item.id}
                                        // if the business plan is evaluated, then the row is highlighted
                                        sx={{ border: '1px solid #014F8650', background: item.isEvaluated ? '#50C87850' : '#fff' }}
                                    >
                                        <TableCell sx={{ border: '1px solid #014F8650' }}>{item.User.UserInfo.company}</TableCell>
                                        <TableCell sx={{ border: '1px solid #014F8650' }}>{item.project_name}</TableCell>
                                        <TableCell sx={{ border: '1px solid #014F8650' }}>{item.industry}</TableCell>
                                        <TableCell sx={{ border: '1px solid #014F8650' }}>{item.opperational_address}</TableCell>
                                        <TableCell>
                                            <Button variant="contained"
                                                fullWidth sx={{ borderRadius: 50, background: "#FFD100", color: "#000"}}
                                                onClick={() => navigate('/business_ideas/' + item.id)}
                                                disableElevation
                                                // if the business plan is evaluated, then the button is disabled
                                                disabled={item.isEvaluated}
                                            >
                                                {t('consultant.rate')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Grid>

        </Grid>
    );
}

export default DashboardConsultant;