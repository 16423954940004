import React, { useState } from "react";
import { db } from "../../app/firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useSelector } from "react-redux";

import Box from '@mui/material/Box'; // Grid version 1

import { useTranslation } from 'react-i18next';

const SendMessage = ({ scroll }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state.users?.user);

  const sendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    const { displayName, photoURL } = {};
    await addDoc(collection(db, "messages"), {
      text: message,
      name: user.userInfo.fname + " " + user.userInfo.lname,
      uid: user.userId,
      createdAt: serverTimestamp(),
    });
    setMessage("");
    scroll.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end', container: scroll.current });
    // scroll.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      className="send-message"
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        //  make input and button appear on the same line
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      noValidate
      autoComplete="off"
      onSubmit={(event) => sendMessage(event)}
    >
     <input
        id="messageInput"
        name="messageInput"
        type="text"
        className="form-input__input"
        placeholder={t('social_network.message')}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button type="submit">
        {t('social_network.send')}
      </button>
    </Box>
  );
};

export default SendMessage;