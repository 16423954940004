import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
        en: {
            translation: require('./translations/en.json'),
        },
        bg: {
          translation: require('./translations/bg.json'),
        },
        el: {
          translation: require('./translations/gr.json'),
        }
    }
  });

export default i18n;