import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid'; // Grid version 1
import TitleBar from '../components/TitleBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import image from '../res/images/register.jpg';

import { useDispatch, useSelector } from 'react-redux'
import { register } from '../features/users'
import { getCompanySectors, getCountries } from '../features/utils';

import { useTranslation } from 'react-i18next';

const RegistrationPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [country, setCountry] = useState(0);
    const [companySector, setCompanySector] = useState(0);
    const [error,  setError] = useState('');

    const countries = useSelector(state => state.utils.countries);
    const companySectors = useSelector(state => state.utils.companySectors);

    const loading = useSelector(state => state.users.status === 'loading');
    const serverError = useSelector(state => state.users.error);

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleChangeCompanySector = (event) => {
        setCompanySector(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(validator(data)) {
            dispatch(register( {
                fname: data.get('fname'),
                lname: data.get('lname'),
                country: country,
                municipality: data.get('municipality'),
                region: data.get('region'),
                city: data.get('city'),
                address: data.get('address'),
                phone: data.get('phone'),
                companySector: companySector,
                company: data.get('company'),
                email: data.get('email'),
                password: data.get('password'),
            })).then((response) => {
                if(response.payload.message === 'User created') {
                    navigate('/login', { state: { message: 'User created successfully, you can now log in' } });
                } else {
                    setError(response.payload.message);
                }
            })
        }
    };

    const validator = (data) => {
        setError('');
        function emailIsValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }
        if (data.get('fname').length < 2) {
            setError(t('register.error_name'));
            return false
        }
        if (data.get('lname').length < 2) {
            setError(t('register.error_lname'));
            return false
        }
        if (country === 0) {
            setError(t('register.error_country'))
            return false
        }
        if (data.get('municipality').length < 2) {
            setError(t('register.error_manucipality'))
            return false
        }
        if (data.get('region').length < 2) {
            setError(t('register.error_region'))
            return false
        }
        if (data.get('city').length < 2) {
            setError(t('register.error_city'))
            return false
        }
        if (data.get('address').length < 2) {
            setError(t('register.error_address'))
            return false
        }
        if (data.get('phone').length < 2) {
            setError(t('register.error_phone'))
            return false
        }
        if (companySector === 0) {
            setError(t('register.error_sector'))
            return false
        }
        if (data.get('company').length < 2) {
            setError(t('register.error_companyName'))
            return false
        }
        if (!emailIsValid(data.get('email'))) {
            setError(t('register.error_email'))
            return false
        }
        if (data.get('password').length < 5) {
            setError(t('register.error_password'))
            return false
        }
        return true
    }

    useEffect(() => {
        dispatch(getCountries())
        dispatch(getCompanySectors())
    }, [])

    return (
        <Grid container sx={{mb: 10}}>
            <TitleBar title={'Register'} />
            <Container maxWidth="lg"  sx={{  display: 'flex', flexDirection: 'row', alignItems: 'center', py: 8 }}>
                <Grid item xs={5}>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: { xs: '100%' },
                        }}
                        alt="registration"
                        src={image}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                        <Grid container spacing={2} >
                            <Grid container item xs={6} direction="column" >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="fname"
                                    label={t('register.fname')}
                                    name="fname"
                                    autoComplete="fname"
                                    autoFocus
                                />
                                <Select
                                    sx={{ mt: 2, mb: 1 }}
                                    labelId="country"
                                    id="country"
                                    name="country"
                                    value={country}
                                    label={t('register.country')}
                                    onChange={handleChangeCountry}
                                >   
                                    <MenuItem value={0} disabled>
                                        <em>{t('register.country')}</em>
                                    </MenuItem>
                                    {countries && countries.length > 0 && countries.map((country) => (
                                        <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="region"
                                    label={t('register.region')}
                                    name="region"
                                    autoComplete="region"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="address"
                                    label={t('register.address')}
                                    name="address"
                                    autoComplete="address"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="company"
                                    label={t('register.companyName')}
                                    name="company"
                                    autoComplete="company"
                                    autoFocus
                                />
                            </Grid>
                            <Grid container item xs={6} direction="column" >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lname"
                                    label={t('register.lname')}
                                    name="lname"
                                    autoComplete="lname"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="municipality"
                                    label={t('register.municipality')}
                                    name="municipality"
                                    autoComplete="municipality"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="city"
                                    label={t('register.city')}
                                    name="city"
                                    autoComplete="city"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="phone"
                                    label={t('register.phone')}
                                    name="phone"
                                    autoComplete="phone"
                                    autoFocus
                                />
                                <Select
                                    sx={{ mt: 2 }}
                                    labelId="companySector"
                                    id="companySector"
                                    name="companySector"
                                    value={companySector}
                                    label={t('register.companySector')}
                                    onChange={handleChangeCompanySector}
                                >
                                     <MenuItem value={0} disabled>
                                        <em>{t('register.companySector')}</em>
                                    </MenuItem>
                                    {companySectors && companySectors.length > 0 && companySectors.map((sector) => (
                                        <MenuItem key={sector.id} value={sector.id}>{sector.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid container item xs={12} direction="column" >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="sector"
                                    label={t('register.industry')}
                                    name="sector"
                                    autoComplete="sector"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t('register.email')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    type='email'
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label={t('register.password')}
                                    name="password"
                                    autoComplete="password"
                                    autoFocus
                                    type='password'
                                />
                                <FormControlLabel
                                    control={<Checkbox value="acceptTerms" color="primary" required />}
                                    label={t('register.acceptTerms')}
                                />
                                {error ? 
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        {error}
                                    </Typography>
                                : null}
                                {serverError ?
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        {serverError.error}
                                    </Typography>
                                : null
                                }
                                {loading ?
                                    <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py:1, px: 3, mt: 2 }}>
                                        <CircularProgress size={20} color="secondary" />
                                    </Button>
                                :
                                    <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py:1, px: 3, mt: 2 }}>
                                        {t('button.register')}
                                    </Button>
                                }
                            </Grid>
                           
                        </Grid>
                    </Box>
                </Grid>
            </Container>
        </Grid>
    );
}

export default RegistrationPage;