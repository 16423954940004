import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';

import { useDispatch } from 'react-redux';
import { createAnswer, updateAnswer } from '../features/openAnswer';
import { useTranslation } from 'react-i18next';

function DescriptionBoxList({ business_idea_id, type, title, openAnswer, isConsultant }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [boxes, setBoxes] = useState([{ shortDesc: '', longDesc: '' }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBoxes(openAnswer.answer ? JSON.parse(openAnswer.answer) : [{ shortDesc: '', longDesc: '' }])
  }, [openAnswer]);

  const handleSave = () => {
    setLoading(true);
    if(openAnswer.answer) {
      dispatch(updateAnswer({ id: openAnswer.id, answer: boxes })).then(() => {
        setLoading(false);
      });
    } else {
      dispatch(createAnswer({ business_idea_id, type, answer: boxes })).then(() => {
        setLoading(false);
      });
    }
  };

  const handleBoxChange = (index, field, value) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index][field] = value;
    setBoxes(updatedBoxes);
  };

  const handleAddBox = () => {
    setBoxes([...boxes, { shortDesc: '', longDesc: '' }]);
  };

  const handleRemoveBox = (index) => {
    const updatedBoxes = [...boxes];
    updatedBoxes.splice(index, 1);
    setBoxes(updatedBoxes);
  };

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <>
      {boxes.map((box, index) => (
        <Box key={index} sx={{ marginTop: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#014F8610',
              padding: 2,
            }}
          >
            <Typography variant="subtitle1" component="div">
              {title} {index + 1}
            </Typography>
            <Box>
              <IconButton onClick={() => handleExpand(index)}>
                <ExpandMoreIcon
                  sx={{
                    transform: expandedIndex === index && 'rotate(180deg)',
                  }}
                />

              </IconButton>
              {!isConsultant &&
              <IconButton onClick={() => handleRemoveBox(index)}>
                <DeleteIcon />
              </IconButton> }
            </Box>
          </Box>
          <Collapse in={expandedIndex === index}
            sx={{
              backgroundColor: '#014F8610',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', px: 3, pt: 1, pb: 3 }}>
              <Grid container spacing={0} >
                <Grid container item xs={12} direction="column" >
                  <TextField
                    label={t('business.short_description')}
                    variant="outlined"
                    value={box.shortDesc}
                    onChange={(event) =>
                      handleBoxChange(index, 'shortDesc', event.target.value)
                    }
                    sx={{ mt: 1, backgroundColor: '#fff' }}
                    disabled={isConsultant}
                  />
                  <TextField
                    label={t('business.long_description')}
                    variant="outlined"
                    value={box.longDesc}
                    onChange={(event) =>
                      handleBoxChange(index, 'longDesc', event.target.value)
                    }
                    sx={{ mt: 1, backgroundColor: '#fff' }}
                    multiline
                    rows={4}
                    disabled={isConsultant}
                  />
                </Grid>
              </Grid>

            </Box>
          </Collapse>
        </Box>
      ))}
      {!isConsultant ?
      <Box sx={{}} >
        {loading && <Typography variant="subtitle1" component="div" sx={{ mt: 2 }}>Saving...</Typography>}
        <Button variant="contained" onClick={handleSave} fullWidth sx={{ borderRadius: 50, background: "#FFD100", color: "#000", py: 1, px: 3, mt: 2, width: 200 }}>
          {t('button.save')}
        </Button>
        <Button variant="contained" onClick={handleAddBox} fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 2, ml:2,  width: 200 }}>
          {t('button.add_new')}
        </Button>
      </Box> : null }

    </>
  );
}

export default DescriptionBoxList;