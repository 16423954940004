import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid'; // Grid version 1

import TitleBar from '../components/TitleBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { useDispatch, useSelector } from 'react-redux'
import { login } from '../features/users'

import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // check if there is a message from the registration page
        if (location.state) {
            setMessage(location.state.message);
        }
    }, [location]);

    const loading = useSelector(state => state.users.status === 'loading');

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (validator(data)) {
            dispatch(login({
                email: data.get('email'),
                password: data.get('password'),
            })).then((response) => {
                if(response.payload.token) {
                    // save the token in the local storage
                    localStorage.setItem('token', response.payload.token);
                    localStorage.setItem('role', response.payload.role)
                    if(response.payload.role == 2){
                        navigate('/consultant');
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    setError('Не можахме да намерим потребител с тези данни')
                }
            })
        }
    };

    const validator = (data) => {
        setError('');
        function emailIsValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }
        if (!emailIsValid(data.get('email'))) {
            setError('Това не е валиден email адрес')
            return false
        }
        if (data.get('password').length < 5) {
            setError('Паролата трябва да е поне 6 знака')
            return false
        }
        return true
    }

    return (
        <Grid container sx={{mb: 10}}>
            <TitleBar title={t('login.login')} />
            <Grid item xs={12}>
                <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 6 }}>
                    {message && <Typography color="green" sx={{ textAlign: 'center' }}>{message}</Typography>}
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {error ?
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                {error}
                            </Typography>
                            : null}
                        
                        {loading ?
                            <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, my: 2 }}>
                                <CircularProgress size={20} color="secondary" />
                            </Button>
                            :
                            <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, my: 2 }}>
                                {t('login.login')}
                            </Button>
                        }
                        <Grid container>
                            <Grid item xs>
                                <Link href="/reset_password" variant="body2">
                                    {t('login.forgot')}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {t('login.register')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    );
}

export default LoginPage;