import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../app/api'

const initialState = {
    notes: null,
    status: 'idle',
    error: null
}

export const getNotes = createAsyncThunk(
    "notes/getNotes",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await api.get('notes/' + arg.business_idea_id + '/' + arg.type);
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createNote = createAsyncThunk(
    "notes/createNote",
    async ({
        text, business_idea_id, type
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('notes', {
                text: text,
                type: type,
                business_idea_id: business_idea_id,
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const notesSlice = createSlice({
    name: 'notes',
    initialState,
    extraReducers: {
        [getNotes.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getNotes.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.notes = action.payload
            state.error = null
        },
        [getNotes.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [createNote.pending]: (state, action) => {
            state.status = 'loading'
        },
        [createNote.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [createNote.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        }
    }
});

export default notesSlice.reducer;