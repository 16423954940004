import axios from 'axios';

// const API_URL = 'http://localhost:5001/api';
const API_URL = process.env.REACT_APP_BASE_URL;

// Set the base URL for your API
axios.defaults.baseURL = API_URL;

// Add an interceptor to add the authorization header
axios.interceptors.request.use(config => {
  // Check if there is a token in the local storage
  const token = localStorage.getItem('token');
  // If there is a token, add it to the request header
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Add an interceptor to handle token expiration and refresh the token
// axios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     const originalRequest = error.config;

//     // If the error is due to an expired token and we haven't already tried to refresh it
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       // Get the refresh token from local storage
//       const refreshToken = localStorage.getItem('refreshToken');

//       // Make a request to refresh the token
//       return axios.post('/refresh-token', { refreshToken })
//         .then(response => {
//           const { token, refreshToken } = response.data;

//           // Save the new token and refresh token to local storage
//           localStorage.setItem('token', token);
//           localStorage.setItem('refreshToken', refreshToken);

//           // Update the authorization header with the new token
//           axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

//           // Retry the original request
//           return axios(originalRequest);
//         })
//         .catch(error => {
//           // Clear the tokens from local storage and redirect to the login page
//           localStorage.removeItem('token');
//           localStorage.removeItem('refreshToken');
//           window.location.href = '/login';
//         });
//     }

//     return Promise.reject(error);
//   }
// );

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    // The server responded with an error status code
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something else happened in making the request that triggered an error
    console.log('Error', error.message);
  }
  return Promise.reject(error);
});

export default axios;