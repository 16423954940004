import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '../app/api'

const initialState = {
    business_ideas: null,
    business_idea: null,
    status: 'idle',
    error: null
}

export const getBusinessIdeas = createAsyncThunk(
    "business_ideas/getBusinessIdeas",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await api.get('business_ideas')
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBusinessIdeasConsultant = createAsyncThunk(
    "business_ideas/getBusinessIdeasConsultant",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await api.get('business_ideas/consultant')
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBusinessIdea = createAsyncThunk(
    "business_ideas/getBusinessIdea",
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`business_ideas/${id}`)
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addBusinessIdea = createAsyncThunk(
    "business_ideas/addBusinessIdea",
    async ({
        project_name, short_description, opperational_address, industry
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('business_ideas', {
                project_name, short_description, opperational_address, industry
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateBusinessIdea = createAsyncThunk(
    "business_ideas/updateBusinessIdea",
    async ({
        id, project_name, short_description, opperational_address
    }, { rejectWithValue }) => {
        try {
            const response = await api.put(`business_ideas/${id}`, {
                project_name, short_description, opperational_address
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBusinessIdeaProgress = createAsyncThunk(
    "business_ideas/getBusinessIdeaProgress",
    async ({ id, isConsultant }, { rejectWithValue }) => {
        console.log(isConsultant)
        try {
            let response
            if(isConsultant) {
                response = await api.get(`business_ideas/${id}/progress/consultant`)
            } else {
                response = await api.get(`business_ideas/${id}/progress`)
            }
            console.log(response)
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBusinessDocument = createAsyncThunk(
    "business_ideas/getBusinessDocument",
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`business_ideas/${id}/generator`)
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const generateImage = createAsyncThunk(
    "business_ideas/generateImage",
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`business_ideas/${id}/generator/image`)
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBusinessDocumentConsultant = createAsyncThunk(
    "business_ideas/getBusinessDocumentConsultant",
    async ({id, areaOfConsultant, datesOfConsultant, totalHoursOfConsultant}, { rejectWithValue }) => {
        try {
            const response = await api.post(`business_ideas/${id}/generator/consultant`, {
                areaOfConsultant : areaOfConsultant,
                datesOfConsultant : datesOfConsultant,
                totalHoursOfConsultant : totalHoursOfConsultant
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
);


export const BusinessIdeasSlice = createSlice({
    name: 'business_ideas',
    initialState,
    extraReducers: {
        [getBusinessIdeas.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getBusinessIdeas.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.business_ideas = action.payload
            state.error = null
        },
        [getBusinessIdeas.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [getBusinessIdeasConsultant.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getBusinessIdeasConsultant.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.business_ideas = action.payload
            state.error = null
        },
        [getBusinessIdeasConsultant.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [getBusinessIdea.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getBusinessIdea.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.business_idea = action.payload
            state.error = null
        },
        [getBusinessIdea.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [addBusinessIdea.pending]: (state, action) => {
            state.status = 'loading'
        },
        [addBusinessIdea.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [addBusinessIdea.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [updateBusinessIdea.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updateBusinessIdea.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [updateBusinessIdea.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [getBusinessIdeaProgress.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getBusinessIdeaProgress.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [getBusinessIdeaProgress.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [getBusinessDocument.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getBusinessDocument.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [getBusinessDocument.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [generateImage.pending]: (state, action) => {
            state.status = 'loading'
        },
        [generateImage.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [generateImage.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [getBusinessDocumentConsultant.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getBusinessDocumentConsultant.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [getBusinessDocumentConsultant.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        }
    }
});

export default BusinessIdeasSlice.reducer;