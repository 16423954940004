import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import RegistrationPage from './pages/RegistrationPage';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import NewPasswordPage from './pages/NewPasswordPage';

import PrivateRoute from './components/PrivateRoute';
import PrivateRouteConsultant from './components/PrivateRouteConsultant';
import DashboardPage from './pages/Dashboard';
import AddBusinessIdea from './pages/businessIdeas/AddBusinessIdea';
import EditBusinessIdea from './pages/businessIdeas/EditBusinessIdea';
import BusinessIdeaDashboard from './pages/businessIdeas/BusinessIdeaDashboard';
import KeyPartners from './pages/businessIdeas/KeyPartners';
import KeyActivities from './pages/businessIdeas/KeyActivities';
import KeyResources from './pages/businessIdeas/KeyResources';
import ValuePropositions from './pages/businessIdeas/ValuePropositions';
import CustomerRelationships from './pages/businessIdeas/CustomerRelationships';
import Channels from './pages/businessIdeas/Channels';
import CustomerSegments from './pages/businessIdeas/CustomerSegments';
import CostStructure from './pages/businessIdeas/CostStructure';
import RevenueStreams from './pages/businessIdeas/RevenueStreams';

import DashboardConsultant from './pages/consultant/DashboardConsultant'

import SocialNetwork from './pages/socialNetwork/SocialNetwork'

import Container from '@mui/material/Container'; // Grid version 1

// get mui theme from https://mui.com/customization/default-theme/
import { ThemeProvider } from '@mui/material/styles';

import THEME from '../src/res/theme'

import checkAuth from './app/checkAuth'

import { useDispatch, useSelector } from 'react-redux';
import { logout } from './features/users'

import i18n from 'i18next';
import './i18n';

function App() {
  const dispatch = useDispatch();

  const isAuthenticated = localStorage.getItem('token') ? true : false;
  // const isConsultant = localStorage.getItem('role');
  const userRole = useSelector((state) => state.users?.user?.role);

  useEffect(() => {
    if (isAuthenticated) {
      checkAuth().then((res) => {
        if (res === false) {
          localStorage.removeItem('token');
          dispatch(logout());
          window.open('/', 'noopener,noreferrer');
        }
      });
    }
  }, []);


  return (
    <BrowserRouter>
      <ThemeProvider theme={THEME}>
        <Container maxWidth={false} disableGutters
          sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}
        >
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset_password" element={<ResetPasswordPage />} />
            <Route path="/new_password/:token" element={<NewPasswordPage />} />
            {userRole == 2 ? 
              <Route exact path='/' element={<PrivateRouteConsultant />}>
                <Route exact path='/consultant' element={<DashboardConsultant />} />
                <Route exact path='/business_ideas/:id' element={<BusinessIdeaDashboard isConsultant />} />
                <Route exact path='/business_ideas/:id/key_partners' element={<KeyPartners isConsultant />} />
                <Route exact path='/business_ideas/:id/key_activities' element={<KeyActivities isConsultant />} />
                <Route exact path='/business_ideas/:id/key_resources' element={<KeyResources isConsultant />} />
                <Route exact path='/business_ideas/:id/value_propositions' element={<ValuePropositions isConsultant />} />
                <Route exact path='/business_ideas/:id/customer_relationships' element={<CustomerRelationships isConsultant />} />
                <Route exact path='/business_ideas/:id/channels' element={<Channels isConsultant />} />
                <Route exact path='/business_ideas/:id/customer_segments' element={<CustomerSegments isConsultant />} />
                <Route exact path='/business_ideas/:id/cost_structure' element={<CostStructure isConsultant />} />
                <Route exact path='/business_ideas/:id/revenue_streams' element={<RevenueStreams isConsultant />} />
              </Route>
            :
              <Route exact path='/' element={<PrivateRoute />}>
                <Route exact path='/dashboard' element={<DashboardPage />} />
                <Route exact path='/business_ideas/create' element={<AddBusinessIdea />} />
                <Route exact path='/business_ideas/edit/:id' element={<EditBusinessIdea />} />
                <Route exact path='/business_ideas/:id' element={<BusinessIdeaDashboard />} />
                <Route exact path='/business_ideas/:id/key_partners' element={<KeyPartners />} />
                <Route exact path='/business_ideas/:id/key_activities' element={<KeyActivities />} />
                <Route exact path='/business_ideas/:id/key_resources' element={<KeyResources />} />
                <Route exact path='/business_ideas/:id/value_propositions' element={<ValuePropositions />} />
                <Route exact path='/business_ideas/:id/customer_relationships' element={<CustomerRelationships />} />
                <Route exact path='/business_ideas/:id/channels' element={<Channels />} />
                <Route exact path='/business_ideas/:id/customer_segments' element={<CustomerSegments />} />
                <Route exact path='/business_ideas/:id/cost_structure' element={<CostStructure />} />
                <Route exact path='/business_ideas/:id/revenue_streams' element={<RevenueStreams />} />
                <Route exact path='/social_network' element={<SocialNetwork />} />
              </Route>
            }
            
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
