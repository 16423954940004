import * as React from 'react';

import Grid from '@mui/material/Grid'; // Grid version 1
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import { useDispatch } from 'react-redux'
import { createAnswer, updateAnswer } from '../features/questions'
import { useTranslation } from 'react-i18next';

const Question = ({ question, business_idea_id, isConsultant, updateQuestions }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    
    const [questionAnswer, setQuestionAnswer] = React.useState(question.answers[0] ? question.answers[0].answer : '');
    const [consultantAnswer, setConsultantAnswer] = React.useState(question.answers[0] ? question.answers[0].consultantAnswer : '');

    const handleChange = (event) => {
        if(isConsultant) {
            setConsultantAnswer(event.target.value);
            if(question.answers[0]) {
                dispatch(updateAnswer({
                    consultantAnswer: event.target.value,
                    id: question.answers[0].id
                }))
            } else {
                dispatch(createAnswer({
                    consultantAnswer: event.target.value,
                    question_id: question.id,
                    business_idea_id: business_idea_id
                })).then(() => {
                    console.log('updateQuestions')
                    updateQuestions(); // Call the updateQuestions function after creating/updating the answer
                });
            }
        } else {
            setQuestionAnswer(event.target.value);
            if(question.answers[0]) {
                dispatch(updateAnswer({
                    answer: event.target.value,
                    id: question.answers[0].id
                }))
            } else {
                dispatch(createAnswer({
                    answer: event.target.value,
                    question_id: question.id,
                    business_idea_id: business_idea_id
                })).then(() => {
                    updateQuestions(); // Call the updateQuestions function after creating/updating the answer
                });
            }
        }
    };

    return (
        <Grid item xs={12}>
            <FormControl>
                <FormLabel id="question" sx={{ fontSize: 17, color: '#000', fontWeight: '500' }}>{question.question}</FormLabel>
                {isConsultant ? 
                   <Typography sx={{ mt:1 }} component="p" variant='otherUserAnswer'>{t('business.user_answer')} {questionAnswer}</Typography>
                : 
                    <Typography sx={{ mt:1 }} component="p" variant='otherUserAnswer'>{t('business.consultant_answer')} {consultantAnswer}</Typography>
                }
                <Typography sx={{ mt:1 }} component="p" variant='otherUserAnswer'>{t('business.your_answer')}</Typography>
                <RadioGroup
                    row
                    aria-labelledby="question"
                    name="row-radio-buttons-group"
                    value={isConsultant ? consultantAnswer : questionAnswer}
                    onChange={handleChange}
                >
                    <FormControlLabel value="1" control={<Radio />} label="1" />
                    <FormControlLabel value="2" control={<Radio />} label="2" />
                    <FormControlLabel value="3" control={<Radio />} label="3" />
                    <FormControlLabel value="4" control={<Radio />} label="4" />
                    <FormControlLabel value="5" control={<Radio />} label="5" />
                    <FormControlLabel value="6" control={<Radio />} label="6" />
                    <FormControlLabel value="7" control={<Radio />} label="7" />
                    <FormControlLabel value="8" control={<Radio />} label="8" />
                    <FormControlLabel value="9" control={<Radio />} label="9" />
                    <FormControlLabel value="10" control={<Radio />} label="10" />
                </RadioGroup>
                </FormControl>
        </Grid>
    );
}

export default Question;