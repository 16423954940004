import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import Grid from '@mui/material/Grid'; // Grid version 1
import TitleBar from '../../components/TitleBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import { useDispatch, useSelector } from 'react-redux'
import { getBusinessIdea, updateBusinessIdea } from '../../features/business_ideas'
import { useTranslation } from 'react-i18next';
const EditBusinessIdea = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const loading = useSelector(state => state.business_ideas.status === 'loading');
    const serverError = useSelector(state => state.business_ideas.error);

    const [project_name, setProjectName] = useState('');
    const [short_description, setShortDescription] = useState('');
    const [opperational_address, setOpperationalAddress] = useState('');

    useEffect(() => {
        dispatch(getBusinessIdea(id)).then((response) => {
            if (response.payload.message === 'Business idea not found') {
                navigate('/dashboard', { state: { message: t('editBusiness.not_found') } });
            } else {
                setProjectName(response.payload.project_name);
                setShortDescription(response.payload.short_description);
                setOpperationalAddress(response.payload.opperational_address);
            }
        });
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (validator(data)) {
            dispatch(updateBusinessIdea( {
                project_name: data.get('project_name'),
                short_description: data.get('short_description'),
                opperational_address: data.get('opperational_address'),
                id: id
            })).then((response) => {
                if(response.payload.message === 'Business idea updated') {
                    navigate('/dashboard');
                } else {
                    setError(t('editBusiness.error'));
                }
            })
        }
    };

    const validator = (data) => {
        setError('');
        if (data.get('project_name').length < 2) {
            setError(t('editBusiness.project_name_error'));
            return false
        }
        if (data.get('short_description').length < 50) {
            setError(t('editBusiness.short_description_error'));
            return false
        }
        if (data.get('opperational_address').length < 5) {
            setError(t('editBusiness.opperational_address_error'));
            return false
        }
        return true
    }

    return (
        <Grid container >
            <TitleBar title={'Edit Business Idea'} description={''} />
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', py: 8, justifyContent: 'center' }}>
                <Grid item xs={7}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                        <Grid container spacing={0} >
                            <Grid container item xs={12} direction="column" >
                                <FormLabel>
                                    <Typography variant="label" component="label" gutterBottom>
                                        {t('editBusiness.project_name')}
                                    </Typography>
                                </FormLabel>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="project_name"
                                    name="project_name"
                                    autoComplete="project_name"
                                    autoFocus
                                    value={project_name}
                                    onChange={(e) => { setProjectName(e.target.value) }}
                                    sx={{ my: 1 }}
                                />
                                <FormLabel>
                                    <Typography variant="label" component="label" gutterBottom>
                                        {t('editBusiness.short_description')}
                                    </Typography>
                                </FormLabel>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="short_description"
                                    name="short_description"
                                    autoComplete="short_description"
                                    autoFocus
                                    multiline
                                    rows={4}
                                    value={short_description}
                                    onChange={(e) => { setShortDescription(e.target.value) }}
                                    sx={{ my: 1 }}
                                />
                                <FormLabel>
                                    <Typography variant="label" component="label" gutterBottom>
                                        {t('editBusiness.opperational_address')}
                                    </Typography>
                                </FormLabel>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="opperational_address"
                                    name="opperational_address"
                                    autoComplete="opperational_address"
                                    autoFocus
                                    value={opperational_address}
                                    onChange={(e) => { setOpperationalAddress(e.target.value) }}
                                    sx={{ my: 1 }}
                                />

                                {error ?
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        {error}
                                    </Typography>
                                    : null}
                                {serverError ?
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        {serverError.error}
                                    </Typography>
                                    : null
                                }
                                {loading ?
                                    <Button variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 2 }}>
                                        <CircularProgress size={20} color="secondary" />
                                    </Button>
                                    :
                                    <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 2, width: 200 }}>
                                        {t('button.save')}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Container>
        </Grid>
    );
}

export default EditBusinessIdea;