import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import TitleBar from '../components/TitleBar';

import { getBusinessIdeas } from '../features/business_ideas'
import { Button } from '@mui/material';

import business_idea_icon from '../res/images/business_idea_icon.png';

import { useTranslation } from 'react-i18next';

const DashboardPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getBusinessIdeas());
    }, [])

    const business_ideas = useSelector((state) => state.business_ideas.business_ideas)

    return (
        <Grid container >
            <TitleBar title={t('dashboard.title')} />
            <Grid item xs={12}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {business_ideas && business_ideas.length === 0 && (<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> {t('no_business')} </Typography>)}
                    <Grid container spacing={5} sx={{ my: 3 }}>
                        {business_ideas && business_ideas.map((business_idea) => (
                            <Grid item xs={12} sm={6} md={4} key={business_idea.id}>
                                <Paper
                                    sx={{
                                        px: 4,
                                        py: 3,
                                        borderRadius: 8,
                                        boxShadow: '0px 4px 20px rgba(1, 79, 134, 0.07)'

                                    }}
                                >
                                    <Box
                                        component="img"
                                        sx={{
                                            width: { xs: 60 },
                                        }}
                                        alt="business idea icon"
                                        src={business_idea_icon}
                                    />
                                    <Box
                                        sx={{
                                            height: 120,
                                        }}
                                    >
                                        <Typography variant="businessIdeasTitle" component="h2" sx={{ flexGrow: 1, mt: 1 }}>
                                            {business_idea.project_name}
                                        </Typography>
                                        <Typography variant='businessIdeasDescription' component="p" sx={{ mt: 1 }}>
                                            {business_idea.short_description.length > 100 ? business_idea.short_description.substring(0, 100) + '...' : business_idea.short_description}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            sx={{
                                                bgcolor: '#FFD100',
                                                color: '#000',
                                                borderRadius: 10,
                                                my: 1,
                                                mr: 0.5,
                                                px: 3,
                                                textTransform: 'none',
                                                width: '100%',
                                                //hover
                                                '&:hover': {
                                                    bgcolor: '#FFD100',
                                                },
                                            }}
                                            onClick={() => navigate('/business_ideas/edit/' + business_idea.id)}>
                                            {t('button.edit')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            sx={{
                                                bgcolor: '#014F86',
                                                color: '#fff',
                                                borderRadius: 10,
                                                my: 1,
                                                ml: 0.5,
                                                px: 2,
                                                textTransform: 'none',
                                                width: '100%',
                                                //hover
                                                '&:hover': {
                                                    bgcolor: '#014F86',
                                                },
                                            }}
                                            onClick={() => navigate('/business_ideas/' + business_idea.id)}>
                                            {t('button.complete')}
                                        </Button>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container sx={{ mt: 2, mb: 5, justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/business_ideas/create')}
                            sx={{
                                bgcolor: '#FFD100',
                                color: '#000',
                                borderRadius: 10,
                                my: 1,
                                px: 4,
                                textTransform: 'none',
                                fontWeight: '300',
                            }}
                        >
                            {t('button.create_business_idea')}
                        </Button>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default DashboardPage;