import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid'; // Grid version 1

import TitleBar from '../components/TitleBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../features/users'

import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const loading = useSelector(state => state.users.status === 'loading');

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (validator(data)) {
            dispatch(resetPassword({
                email: data.get('email'),
            })).then((response) => {
                if(response.payload.message === "Password reset email sent") {
                    navigate('/login', { state: { message: t('resetPassword.success_message') } });
                } else {
                    setError(t('resetPassword.error_message'))
                }
            })
        }
    };

    const validator = (data) => {
        setError('');
        function emailIsValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }
        if (!emailIsValid(data.get('email'))) {
            setError(t('resetPassword.error_email'))
            return false
        }
        return true
    }

    return (
        <Grid container sx={{mb: 10}}>
            <TitleBar title={t('resetPassword.title')} />
            <Grid item xs={12}>
                <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 6 }}>
                    {message && <Typography color="green" sx={{ textAlign: 'center' }}>{message}</Typography>}
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('resetPassword.email')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        {error ?
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                {error}
                            </Typography>
                            : null}
                        {loading ?
                            <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, my: 2 }}>
                                <CircularProgress size={20} color="secondary" />
                            </Button>
                            :
                            <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, my: 2 }}>
                                {t('resetPassword.button')}
                            </Button>
                        }
                    </Box>
                </Container>
            </Grid>
        </Grid>
    );
}

export default ResetPasswordPage;