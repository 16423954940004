// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDEs9MiTz9JM_IFGUm76X-3b3feL7DNnlo",
  authDomain: "evaluate-entervalue.firebaseapp.com",
  projectId: "evaluate-entervalue",
  storageBucket: "evaluate-entervalue.appspot.com",
  messagingSenderId: "396008402980",
  appId: "1:396008402980:web:a478045e69b3651d5d68b1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);