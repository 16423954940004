import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid'; // Grid version 1
import TitleBar from '../../components/TitleBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import { useDispatch, useSelector } from 'react-redux'
import { addBusinessIdea } from '../../features/business_ideas'
import { useTranslation } from 'react-i18next';

const AddBusinessIdea = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const loading = useSelector(state => state.business_ideas.status === 'loading');
    const serverError = useSelector(state => state.business_ideas.error);

    const [industry, setIndustry] = useState('');

    const handleIndustryChange = (event) => {
        setIndustry(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (validator(data)) {
            dispatch(addBusinessIdea( {
                project_name: data.get('project_name'),
                short_description: data.get('short_description'),
                opperational_address: data.get('opperational_address'),
                industry: industry === 'other' ? data.get('other_industry') : industry,
            })).then((response) => {
                if(response.payload.message === 'Business idea created') {
                    navigate('/dashboard', { state: { message: t('editBusiness.created') } });
                } else {
                    setError(response.payload.message);
                }
            })
        }
    };

    const validator = (data) => {
        setError('');
        if (data.get('project_name').length < 2) {
            setError(t('editBusiness.project_name_error'));
            return false
        }
        if (data.get('short_description').length < 50) {
            setError(t('editBusiness.short_description_error'));
            return false
        }
        if (data.get('opperational_address').length < 5) {
            setError(t('editBusiness.opperational_address_error'));
            return false
        }
        if (industry.length < 1) {
            setError(t('editBusiness.select_industy_error'));
            return false
        }
        if (industry === 'other' && data.get('other_industry').length < 2) {
            setError(t('editBusiness.other_industy_error'));
            return false
        }
        return true
    }

   

    return (
        <Grid container >
            <TitleBar title={t('editBusiness.title')} />
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', py: 8, justifyContent: 'center' }}>
                <Grid item xs={7}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                        <Grid container spacing={0} >
                            <Grid container item xs={12} direction="column" >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="project_name"
                                    label={t('editBusiness.project_name')}
                                    name="project_name"
                                    autoComplete="project_name"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="short_description"
                                    label={t('editBusiness.short_description')}
                                    name="short_description"
                                    autoComplete="short_description"
                                    autoFocus
                                    multiline
                                    rows={4}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="opperational_address"
                                    label={t('editBusiness.opperational_address')}
                                    name="opperational_address"
                                    autoComplete="opperational_address"
                                    autoFocus
                                />
                                <FormControl
                                    sx={{ my: 2, }}
                                >
                                    <FormLabel id="radio-buttons-group-label">{t('editBusiness.industy')}</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-group-label"
                                        defaultValue="tourism"
                                        name="radio-buttons-group"
                                        value={industry}
                                        onChange={handleIndustryChange}
                                    >
                                        <FormControlLabel value="tourism" control={<Radio />} label={t('editBusiness.tourism')} />
                                        <FormControlLabel value="trade" control={<Radio />} label={t('editBusiness.trade')} />
                                        <FormControlLabel value="culture" control={<Radio />} label={t('editBusiness.creative_economy')} />
                                        <FormControlLabel value="other" control={<Radio />} label={t('editBusiness.other')} />
                                    </RadioGroup>
                                </FormControl>
                                {industry === "other" && (
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="other_industry"
                                    label={t('editBusiness.other_industy')}
                                    name="other_industry"
                                    autoComplete="other_industry"
                                    autoFocus
                                /> )}

                                {error ?
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        {error}
                                    </Typography>
                                    : null}
                                {serverError ?
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        {serverError.error}
                                    </Typography>
                                    : null
                                }
                                {loading ?
                                    <Button variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 2 }}>
                                        <CircularProgress size={20} color="secondary" />
                                    </Button>
                                    :
                                    <Button type="submit" variant="contained" fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 2, width: 150 }}>
                                        {t('button.publish')}
                                    </Button>
                                }

                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Container>
        </Grid>
    );
}

export default AddBusinessIdea;