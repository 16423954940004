import React, { useEffect, useRef, useState } from "react";

import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';

import TitleBar from '../../components/TitleBar';

import { useTranslation } from 'react-i18next';

import { db } from "../../app/firebase";
import {
    query,
    collection,
    orderBy,
    onSnapshot,
    limit,
} from "firebase/firestore";
import Message from "./Message";
import SendMessage from "./SendMessage";

import "./style.css";


const SocialNetwork = () => {
    const { t } = useTranslation();
    const [messages, setMessages] = useState([]);
    const scroll = useRef();

    useEffect(() => {
        const q = query(
            collection(db, "messages"),
            orderBy("createdAt", "desc"),
            limit(50)
        );
        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            const fetchedMessages = [];
            QuerySnapshot.forEach((doc) => {
                fetchedMessages.push({ ...doc.data(), id: doc.id });
            });
            const sortedMessages = fetchedMessages.sort(
                (a, b) => a.createdAt - b.createdAt
            );
            setMessages(sortedMessages);
        });
        return () => unsubscribe;
    }, []);

    return (
        <Grid container sx={{ mb: 10 }}>
            <TitleBar title={t('social_network.title')} />
            <Grid item xs={12}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Grid container sx={{ my: 3 }}>
                        <Grid className="chatbox" item xs={12} sx={{  padding: 3,  background: '#fff', border: "solid #014F86 1px" , height: 500, overflow: 'auto', position: 'relative' }}>
                            {messages?.map((message) => (
                                <Message key={message.id} message={message} />
                            ))}
                            {/* when a new message enters the chat, the screen scrolls down to the scroll div */}
                            <span ref={scroll}></span>
                        </Grid>
                        <Grid
                            xs={12}
                            sx={{
                                background: '#014F8610'
                            }}
                            >
                                <SendMessage scroll={scroll} />
                            </Grid>
                    </Grid>

                </Container>
            </Grid>
        </Grid>
    );
}

export default SocialNetwork;