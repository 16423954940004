import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../app/api'

const initialState = {
    questions: null,
    status: 'idle',
    error: null
}

export const getQuestions = createAsyncThunk(
    "questions/getQuestions",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await api.get('questions/' + arg.type + '/' + arg.business_idea_id);
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createAnswer = createAsyncThunk(
    "questions/createAnswer",
    async ({
        answer, consultantAnswer, question_id, business_idea_id
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('answers', {
                answer: answer,
                consultantAnswer: consultantAnswer,
                question_id: question_id,
                business_idea_id: business_idea_id,
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAnswer = createAsyncThunk(
    "questions/updateAnswer",
    async ({
        answer, consultantAnswer, id
    }, { rejectWithValue }) => {
        try {
            const response = await api.put('answers/' + id, {
                answer: answer,
                consultantAnswer: consultantAnswer,
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const QuestionsSlice = createSlice({
    name: 'questions',
    initialState,
    extraReducers: {
        [getQuestions.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getQuestions.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.questions = action.payload
            state.error = null
        },
        [getQuestions.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [createAnswer.pending]: (state, action) => {
            state.status = 'loading'
        },
        [createAnswer.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [createAnswer.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [updateAnswer.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updateAnswer.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.error = null
        },
        [updateAnswer.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        }
    }
});

export default QuestionsSlice.reducer;