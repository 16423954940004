import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid'; // Grid version 1

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'
import { getNotes, createNote } from '../features/notes'
import { useTranslation } from 'react-i18next';

const Notes = ({ business_idea_id, type }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const [note, setNote] = useState("");
    const [error, setError] = useState('')
    const loading = useSelector(state => state.notes.status === 'loading');
    const serverError = useSelector(state => state.notes.error);

    const notes = useSelector(state => state.notes.notes)

    const submitNewNote = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (validator(data)) {
            dispatch(createNote({
                text: data.get('note'),
                type: type,
                business_idea_id: business_idea_id
            })).then((response) => {
                if (response.payload.message === 'Note created') {
                    setNote("");
                    dispatch(getNotes({
                        business_idea_id: business_idea_id,
                        type: type
                    }))
                } else {
                    setError(response.payload.message);
                }
            })
        }
    };

    const validator = (data) => {
        setError('');
        if (data.get('note').length < 2) {
            setError(t('business.notes_error'))
            return false
        }
        return true
    }

    useEffect(() => {
        dispatch(getNotes({
            business_idea_id: business_idea_id,
            type: type
        }))
    }, []);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }


    return (
        <Grid item xs={12}>
            <Typography component="h2" variant='h2Title'>
                {t('business.notes')}
            </Typography>
            {notes && notes.length > 0 && notes.map((note, index) => (
                <Box key={index} sx={{ mb: 4, pb: 4, mt: 4, borderBottom: '1px solid #014F8650' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                        <Box>
                            <Avatar {...stringAvatar(note.User.UserInfo.fname + ' ' + note.User.UserInfo.lname)}
                                sx={{
                                    height: 40,
                                    width: 40
                                }}
                            />
                        </Box>
                        <Box
                            sx={{ ml: 2 }}
                        >
                            <Typography component="h3" variant='smallHeading' sx={{ mb: 0.5 }}>{note.User.UserInfo.fname} {note.User.UserInfo.lname}</Typography>
                            <Typography component="span" variant='smallDescription'>{moment(note.createdAt).format("MMM Do YY")}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography component="p" variant='p'>
                            {note.text}
                        </Typography>
                    </Box>
                </Box>
            ))}
            <Box component="form" onSubmit={submitNewNote} sx={{ mt: 0 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="note"
                    label={t('business.write_notes')}
                    name="note"
                    sx={{ mt: 1, backgroundColor: '#fff' }}
                    multiline
                    rows={4}
                    value={note}
                    onChange={(event) => setNote(event.target.value)}
                />
                {error ?
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        {error}
                    </Typography>
                    : null}
                {serverError ?
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        {serverError.error}
                    </Typography>
                    : null
                }
                {loading ?
                    <Button type="submit" variant="contained" sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 2 }}>
                        <CircularProgress size={20} color="secondary" />
                    </Button>
                    :
                    <Button type="submit" variant="contained" sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 2 }}>
                        {t('business.save')}
                    </Button>
                }

            </Box>

        </Grid>
    );
}

export default Notes;