import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import jwtDecode from 'jwt-decode';
import { logout } from '../features/users'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const checkAuth = async () => {
    // Retrieve the JWT from local storage or secure storage
    const jwt = localStorage.getItem('token');
    const isConsultant = localStorage.getItem('role');
    if (!jwt) {
      // If no JWT is present, return false
      return false;
    }
  
    try {
      // Decode the JWT using a library such as jwt-decode
      const decoded = jwtDecode(jwt);
    
      // Check if the token has expired
      if (decoded.exp < Date.now() / 1000) {
        console.log('token expired');
        // If the token has expired, return false
        return false;
      }

      if(isConsultant != 2){
        console.log('not consultant')
        return false;
      } 
      // If the token is valid, return true
      return true;
    } catch (err) {
      // If there is an error decoding the token, return false
      return false;
    }
  };
  

const PrivateRouteConsultant = () => {
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    const isAuthenticated = localStorage.getItem('token') ? true : false;
    const isConsultant = localStorage.getItem('role');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated || isConsultant == 2) {
            checkAuth().then((res) => {
                if(res === false) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('isConsultant');
                    dispatch(logout());
                    navigate('/');
                }
            });
        }
    }, []);

    return (isAuthenticated && isConsultant) ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRouteConsultant;