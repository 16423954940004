import React, { useState } from "react";
import { useSelector } from "react-redux";

const Message = ({ message }) => {
  const user = useSelector((state) => state.users.user);
  return (
    <div className={`chat-bubble ${message.uid === user.userId ? "right" : ""}`}>
      <div className="chat-bubble__right">
        <p className="user-name">{message.name}</p>
        <p className="user-message">{message.text}</p>
      </div>
    </div>
  );
};

export default Message