import { createTheme } from '@mui/material/styles';

const THEME = createTheme({
    typography: {
      "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500,
      titleBarTitle: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 30,
        fontWeight: 700,
        color: '#014F86'
      },
      titleBarDescription: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 20,
        fontWeight: 200,
        color: '#000'
      },
      otherUserAnswer: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeight: 300,
        color: '#000'
      },
      copiright: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        fontWeight: 300,
        color: '#fff',
        textAlign: 'center'
      },
      businessIdeasTitle: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 25,
        fontWeight: 500,
        color: '#014F86',
        wordWrap: 'break-word',
      },
      businessIdeasDescription: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        fontWeight: 300,
        color: '#000',
        wordWrap: 'break-word',
      },
      label: {
        fontFamily: `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 13,
        fontWeight: 300,
        color: '#000',
        wordWrap: 'break-word',
      },
      h2Title: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 22,
        fontWeight: 600,
        color: '#014F86',
      },
      body: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        fontWeight: 300,
        color: '#000',
      },
      heroHeading1: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        color: '#fff',
        fontSize: 38,
        fontWeight: 600
      },
      heroHeading2: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 25, 
        fontWeight: 400,
        color: '#fff'
      },
      heroDescription: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        fontWeight: 400,
        color: '#fff'
      },
      lightButton: {
        borderRadius: 50, background: "#FFF", color: '#000', py: 1, px: 3, my: 2 
      },
      smallHeading: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        fontWeight: 400,
        color: '#014F86'
      },
      smallHeading2: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 17,
        fontWeight: 400,
        color: '#000'
      },
      smallDescription: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        fontWeight: 300,
        color: '#000'
      },
      beforeSectionHeading: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        fontWeight: 400,
        color: '#014F86'
      },
      sectionHeading: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 27,
        fontWeight: 500,
        color: '#000'
      },
      p: {
        "fontFamily": `"Nunito", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        fontWeight: 300,
        color: '#000'
      }
    }
  });

  export default THEME