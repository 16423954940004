import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import api from '../app/api'

const initialState = {
    user: null,
    status: 'idle',
    error: null
}

export const register = createAsyncThunk(
    "users/register",
    async ({ 
        fname, lname, country, municipality, region, city, address, phone, companySector, company, email, password 
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('users/register', {
                fname, lname, country, municipality, region, city, address, phone, companySector, company, email, password
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const login = createAsyncThunk(
    "users/login",
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await api.post('users/login', {
                email, password
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const resetPassword = createAsyncThunk(
    "users/resetPassword",
    async ({ email }, { rejectWithValue }) => {
        try {
            const response = await api.post('users/reset-password', {
                email
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updatePassword = createAsyncThunk(
    "users/updatePassword",
    async ({ password, token }, { rejectWithValue }) => {
        try {
            const response = await api.post('users/update-password', {
                password, token
            })
            if(response.status < 300) {
                return response.data
            } else {
                return rejectWithValue(response.data)
            }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null
        }
    },
    extraReducers: {
        [register.pending]: (state, action) => {
            state.status = 'loading'
        },
        [register.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.user = action.payload
        },
        [register.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [login.pending]: (state, action) => {
            state.status = 'loading'
        },
        [login.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.user = action.payload
        },
        [login.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [resetPassword.pending]: (state, action) => {
            state.status = 'loading'
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.status = 'succeeded'
        },
        [resetPassword.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        },
        [updatePassword.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updatePassword.fulfilled]: (state, action) => {
            state.status = 'succeeded'
        },
        [updatePassword.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload;
        }
    }
})

export const { logout } = userSlice.actions
export default userSlice.reducer


