import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import TitleBar from '../../components/TitleBar';
import Question from '../../components/Question';
import DescriptionBoxList from '../../components/DescriptionBoxList';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { getQuestions } from '../../features/questions'
import { getAnswer } from '../../features/openAnswer';

import Notes from '../../components/Notes'
import { useTranslation } from 'react-i18next';

const RevenueStreams = ({ isConsultant }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const [questions, setQuestions] = useState([]);
    const [avarageScore, setAvarageScore] = useState(0);
    const [avarageScoreConsultant, setAvarageScoreConsultant] = useState(0);
    const [openAnswer, setOpenAnswer] = useState([]);

    useEffect(() => {
        dispatch(getQuestions({ type: 'revenue_streams', business_idea_id: id })).then((response) => {
            setQuestions(response.payload);
            const answers = response.payload.flatMap(item => item.answers);
            const totalScore = answers.reduce((sum, answer) => sum + answer.answer, 0);
            const totalScoreConsultant = answers.reduce((sum, answer) => sum + answer.consultantAnswer, 0);
            setAvarageScore(totalScore / answers.length);
            setAvarageScoreConsultant(totalScoreConsultant / answers.length);
        });
        dispatch(getAnswer({ type: 'revenue_streams', business_idea_id: id })).then((response) => {
            setOpenAnswer(response.payload);
        });
    }, [])

    const updateQuestions = () => {
        dispatch(getQuestions({ type: 'cost_structure', business_idea_id: id })).then((response) => {
            setQuestions(response.payload);
        });
    };


    return (
        <Grid container >
            <TitleBar title={t('business.revenue_streams_title')}/>
            {!isConsultant &&
            <Grid item xs={12} sx={{ background: '#FFD10010', py: 6 }}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography component="h2" variant='h2Title'>
                    {t('business.definition')}
                    </Typography>
                    <Typography component="p" variant='body'>
                        {t('revenue_streams.text1')}
                    </Typography>
                    <Typography component="p" variant='body' sx={{mt:1, mb:1}}>
                        {t('revenue_streams.text2')}
                    </Typography>
                    <ul>
                        <li><Typography component="p" variant='body'>{t('revenue_streams.bulet1')}</Typography></li>
                        <li><Typography component="p" variant='body'>{t('revenue_streams.bulet2')}</Typography></li>
                    </ul>

                </Container>
            </Grid> }
            <Grid item xs={12} sx={{ py: 6 }}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography component="h2" variant='h2Title'>
                        
                    </Typography>
                    {isConsultant ?
                        <Typography component="h2" variant='h2Title'>
                            {t('business.revenue_streams_title')}
                        </Typography> :
                        <Typography component="h2" variant='h2Title'>
                            {t('revenue_streams_title.add_title')}
                        </Typography>
                    }
                    <DescriptionBoxList business_idea_id={id} title={t('business.revenue_streams_title')} type={'revenue_streams'} openAnswer={openAnswer[0] ? openAnswer[0] : []} isConsultant={isConsultant} />
                </Container>
            </Grid>
            <Grid item xs={12} sx={{ py: 3 }}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography component="h2" variant='h2Title'>
                        {t('business.anwsers_title')}
                    </Typography>
                    <Typography component="p" variant='body' sx={{ mt: 2 }}>
                        {t('business.answers_sub_title')}
                    </Typography>
                    <Grid container spacing={2} sx={{ my: 3 }}>
                        {questions && questions.length > 0 && questions.map((question, index) => (
                            <Question key={index} question={question} business_idea_id={id} isConsultant={isConsultant} updateQuestions={updateQuestions}/>
                        ))}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                        <Typography component="div" variant='h2Title' sx={{ mt: 2,  }}>
                            {t('business.final_score_user')} {avarageScore ? avarageScore.toFixed(2) : t('business.not_answered') }
                        </Typography>
                        <Typography component="div" variant='h2Title' sx={{ mt: 2,  }}>
                            {t('business.final_score_consultant')} {avarageScoreConsultant ? avarageScoreConsultant.toFixed(2) : t('business.not_answered') }
                        </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ my: 1 }}>
                        <Button variant="contained" onClick={() => navigate('/business_ideas/' + id)} fullWidth sx={{ borderRadius: 50, background: "#014F86", py: 1, px: 3, mt: 1, ml: 2, width: 300 }}>
                            {t('business.go_back')}
                        </Button>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} sx={{ background: '#FFD10010', py: 6 }}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Grid container spacing={2} sx={{ my: 0 }}>
                        <Notes business_idea_id={id} type={'revenue_streams'} />
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default RevenueStreams;