import { Link } from 'react-router-dom';
import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Grid version 1
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../features/users';
import { useNavigate } from 'react-router-dom';

import logo from '../res/images/logo.png';

import { useTranslation } from 'react-i18next';

import bg_flag from '../res/images/bg-flag.svg';
import gr_flag from '../res/images/gr-flag.svg';
import uk_flag from '../res/images/gb-flag.svg';

// make header
const Header = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.users?.user?.userInfo);

    const handleLanguageChange = (lang) => {
        try {
            i18n.changeLanguage(lang);
        }
        catch (error) {
            console.log(error)
        }
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(logout());
        navigate('/');
    }

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };


    return (
        <AppBar component='header' position="static" sx={{ background: "#fff", minHeight: 80, color: "#000000", fontSize: 18, py: 2 }}>
            <Toolbar sx={{ px: 4 }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <img src={logo} alt="logo" style={{ width: '160px', height: 'auto' }} />

                <Box sx={{ flexGrow: 1 }} />
                {(localStorage.getItem('token') && localStorage.getItem('role') == 1) ? (
                    <>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                            <Button color="inherit" component={Link} to="/">{t('menu.home')}</Button>
                            <Button color="inherit" component={Link} to="/dashboard">{t('menu.dashboard')}</Button>
                            <Button color="inherit" component={Link} to="https://learn.entervalue.eu">{t('menu.learn')}</Button>
                            <Button color="inherit" component={Link} to="/social_network">{t('menu.social_network')}</Button>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                <Box color="inherit" onClick={() => handleLanguageChange('en')}>
                                    <img src={uk_flag} alt="uk_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                </Box>
                                <Box color="inherit" onClick={() => handleLanguageChange('bg')}>
                                    <img src={bg_flag} alt="bg_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                </Box>
                                <Box color="inherit" onClick={() => handleLanguageChange('el')}>
                                    <img src={gr_flag} alt="gr_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: { xs: 'flex' } }}>
                            <Avatar {...stringAvatar(user.fname + ' ' + user.lname)}
                                onClick={handleClick}
                                sx={{
                                    cursor: 'pointer',
                                    height: 40,
                                    width: 40,
                                    ml: 2
                                }}
                            />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    horizontal: 'left',
                                    vertical: 'bottom'
                                }}
                            >
                                <Box
                                    sx={{
                                        py: 1.5,
                                        px: 2
                                    }}
                                >
                                    <Typography variant="overline">
                                        Account
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        {user.fname + ' ' + user.lname}
                                    </Typography>
                                </Box>
                                <Divider />
                                <MenuList
                                    disablePadding
                                    dense
                                    sx={{
                                        p: '8px',
                                        '& > *': {
                                            borderRadius: 1
                                        }
                                    }}
                                >
                                    <MenuItem onClick={handleLogout}>
                                        Sign out
                                    </MenuItem>
                                </MenuList>
                            </Popover>
                        </Box>
                    </>
                ) : (localStorage.getItem('token') && localStorage.getItem('role') == 2) ?
                    <>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                            <Button color="inherit" component={Link} to="/consultant">{t('menu.all_buisiness_plans_menu')}</Button>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                <Box color="inherit" onClick={() => handleLanguageChange('en')}>
                                    <img src={uk_flag} alt="uk_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                </Box>
                                <Box color="inherit" onClick={() => handleLanguageChange('bg')}>
                                    <img src={bg_flag} alt="bg_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                </Box>
                                <Box color="inherit" onClick={() => handleLanguageChange('el')}>
                                    <img src={gr_flag} alt="gr_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: { xs: 'flex' } }}>
                            <Avatar {...stringAvatar(user.fname + ' ' + user.lname)}
                                onClick={handleClick}
                                sx={{
                                    cursor: 'pointer',
                                    height: 40,
                                    width: 40,
                                    ml: 2
                                }}
                            />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    horizontal: 'left',
                                    vertical: 'bottom'
                                }}
                            >
                                <Box
                                    sx={{
                                        py: 1.5,
                                        px: 2
                                    }}
                                >
                                    <Typography variant="overline">
                                        Account
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                    >
                                        {user.fname + ' ' + user.lname}
                                    </Typography>
                                </Box>
                                <Divider />
                                <MenuList
                                    disablePadding
                                    dense
                                    sx={{
                                        p: '8px',
                                        '& > *': {
                                            borderRadius: 1
                                        }
                                    }}
                                >
                                    <MenuItem onClick={handleLogout}>
                                        {t('sign_out')}
                                    </MenuItem>
                                </MenuList>
                            </Popover>
                        </Box>
                    </>
                    : (
                        <>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                                <Button color="inherit" component={"a"} href="/"> {t('menu.home')} </Button>
                                <Button color="inherit" component={"a"} href="/#about"> {t('menu.about')} </Button>
                                <Button color="inherit" component={"a"} href="/#how"> {t('menu.how')}</Button>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                    <Box color="inherit" onClick={() => handleLanguageChange('en')} >
                                        <img src={uk_flag} alt="uk_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                    </Box>
                                    <Box color="inherit" onClick={() => handleLanguageChange('bg')}>
                                        <img src={bg_flag} alt="bg_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                    </Box>
                                    <Box color="inherit" onClick={() => handleLanguageChange('el')}>
                                        <img src={gr_flag} alt="gr_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: 'none', md: 'flex', my: 2 } }}>
                                <Button variant="contained" sx={{ borderRadius: 50, background: "#014F86", pt: 1, pb: 1, pl: 3, pr: 3, ml: 2 }}
                                    component={Link} to="/login"
                                >
                                    {t('menu.start_now')}
                                </Button>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <Button color="inherit" component={Link} to="/login">{t('menu.login')}</Button>
                            </Box>
                        </>
                    )
                }
            </Toolbar>
            <Drawer
                sx={{
                    color: '#000000',

                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerOpen ? '240px' : '0px' }
                }}
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
            >
                <List
                    sx={{
                        color: '#000000',
                    }}
                >
                    {localStorage.getItem('token') && localStorage.getItem('role') == 1 ? (
                        <>
                            <ListItem component="a" href="/dashboard" onClick={closeDrawer}>
                                <ListItemText primary={t('menu.dashboard')} />
                            </ListItem>
                            <ListItem component="a" href="https://learn.entervalue.eu" onClick={closeDrawer}>
                                <ListItemText primary={t('menu.learn')} />
                            </ListItem>
                            <ListItem component="a" href="#" onClick={closeDrawer}>
                                <ListItemText primary={t('menu.social_network')} />
                            </ListItem>
                        </>
                    ) : localStorage.getItem('token') && localStorage.getItem('role') == 2 ? (
                        <>
                            <ListItem component="a" href="/consultant" onClick={closeDrawer}>
                                <ListItemText primary={t('menu.all_buisiness_plans_menu')} />
                            </ListItem>
                        </>
                    ) : (
                        <>
                            <ListItem component="a" href="/" onClick={closeDrawer}>
                                <ListItemText primary={t('menu.home')} />
                            </ListItem>
                            <ListItem component="a" href="/#about" onClick={closeDrawer}>
                                <ListItemText primary={t('menu.about')} />
                            </ListItem>
                            <ListItem component="a" href="/#how" onClick={closeDrawer}>
                                <ListItemText primary={t('menu.how')} />
                            </ListItem>
                        </>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        <Box color="inherit" onClick={() => handleLanguageChange('en')}>
                            <img src={uk_flag} alt="uk_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                        </Box>
                        <Box color="inherit" onClick={() => handleLanguageChange('bg')}>
                            <img src={bg_flag} alt="bg_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                        </Box>
                        <Box color="inherit" onClick={() => handleLanguageChange('el')}>
                            <img src={gr_flag} alt="gr_flag" style={{ width: '20px', height: 'auto', cursor: 'pointer' }} />
                        </Box>
                    </Box>
                </List>
            </Drawer>
        </AppBar>

    );
};

export default Header;