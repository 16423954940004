import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';


const LinearProgressWithLabel = (props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
                <LinearProgress variant="determinate" {...props}
                    sx={{
                        height: 22,
                        bgcolor: '#F1F1F1',
                        '& .MuiLinearProgress-bar': {
                            bgcolor: '#FFD100',
                        },
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 10,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        fontWeight: 'bold',
                    }}
                >
                    <Typography variant="body2" color="text.primary">{`${Math.round(props.value)}%`} </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default LinearProgressWithLabel;

